/* global BigInt */

import { useEffect } from "react";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { useTonConnect } from "@/hooks/useTonconnect";
import { JettonWallet } from "@/wrappers/JettonWallet";
import { v4 as uuidv4 } from "uuid";
import TonWeb from "tonweb";

import {
  INVOICE_WALLET_ADDRESS,
  USDT_MASTER_ADDRESS,
  SIGN_IN_CONTRACT_ADDRESS,
} from "@/constants/common.js";
import { JETTON_TRANSFER_GAS_FEES } from "@/constants/fees.constants.js";

import { beginCell, toNano } from "@ton/ton";
import { Address } from "@ton/core";

const TonConnectComponent = () => {
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const { sender, tonClient } = useTonConnect();
  const address = useTonAddress();
  const tonweb = new TonWeb();

  useEffect(() => {
    // 註冊事件監聽器
    document.addEventListener(
      "unityTonConnect_ConnectToWallet",
      handleConnectToWallet
    );
    document.addEventListener(
      "unityTonConnect_DisconnectWallet",
      handleDisconnectWallet
    );
    document.addEventListener(
      "unityTonConnect_GetUserWalletAddress",
      handleGetUserWalletAddress
    );
    document.addEventListener(
      "unityTonConnect_BuyItemWithTon",
      handleBuyItemWithTon
    );
    document.addEventListener(
      "unityTonConnect_BuyItemWithUsdt",
      handleBuyItemWithUsdt
    );
    document.addEventListener(
      "unityTonConnect_GetTonBalance",
      handleGetTonBalance
    );
    document.addEventListener(
      "unityTonConnect_GetUsdtBalance",
      handleGetUsdtBalance
    );
    document.addEventListener("unityTonConnect_DailySignIn", handleDailySignIn);

    // 清理事件監聽器
    return () => {
      document.removeEventListener(
        "unityTonConnect_ConnectToWallet",
        handleConnectToWallet
      );
      document.removeEventListener(
        "unityTonConnect_DisconnectWallet",
        handleDisconnectWallet
      );
      document.removeEventListener(
        "unityTonConnect_GetUserWalletAddress",
        handleGetUserWalletAddress
      );
      document.removeEventListener(
        "unityTonConnect_BuyItemWithTon",
        handleBuyItemWithTon
      );
      document.removeEventListener(
        "unityTonConnect_BuyItemWithUsdt",
        handleBuyItemWithUsdt
      );
      document.removeEventListener(
        "unityTonConnect_GetTonBalance",
        handleGetTonBalance
      );
      document.removeEventListener(
        "unityTonConnect_GetUsdtBalance",
        handleGetUsdtBalance
      );
      document.removeEventListener(
        "unityTonConnect_DailySignIn",
        handleDailySignIn
      );
    };
  }, [tonConnectUI, address]);

  useEffect(() => {
    if (address) {
      try {
        // 把錢包資料傳遞給 Unity
        window.unityInstance.SendMessage(
          "TonWalletAccount",
          "UserAccountData",
          address
        );
      } catch (error) {
        console.error("Error sending wallet data to Unity:", error);
        // 把錯誤狀態傳遞給 Unity
      }
    }
  }, [address]);

  // 連接 Ton Wallet 功能
  const handleConnectToWallet = async (event) => {
    console.log("ConnectToWallet");
    try {
      tonConnectUI.openModal();
    } catch (error) {
      console.error("Error connecting to wallet:", error);
    }
  };

  // 解除連接 Ton Wallet 功能
  const handleDisconnectWallet = async (event) => {
    console.log("DisconnectWallet");
    try {
      await tonConnectUI.disconnect();
    } catch (error) {
      console.error("Error disconnecting wallet:", error);
    }
  };

  // 顯示 Ton Wallet 連接狀態
  const handleGetUserWalletAddress = async (event) => {
    console.log("GetUserWalletAddress");
    try {
      // 把錢包狀態傳遞給 Unity
      window.unityInstance.SendMessage(
        "TonWalletAccount",
        "GetUserWalletAddress",
        address // 如果是 undefined 代表沒有連接錢包
      );
    } catch (error) {
      console.error("Error getting wallet status:", error);
    }
  };

  // 商城 Ton 付費功能
  const handleBuyItemWithTon = async (event) => {
    console.log("BuyItemWithTon");
    try {
      if (!address) {
        handleConnectToWallet();
      } else {
        const priceNanoTon = event.detail.priceNanoTon;
        const userId = event.detail.userId;
        const itemId = event.detail.itemId;
        const walletAddress =
          tonConnectUI?.connector?._wallet?.account?.address;
        if (!walletAddress) {
          throw new Error("Please connect wallet first");
        }
        // 建立交易訊息
        const body = beginCell()
          .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
          .storeStringTail(`User ${userId} buying item ${itemId} with TON`)
          .endCell();
        const transaction = {
          validUntil: Math.floor(new Date() / 1000) + 360,
          messages: [
            {
              address: INVOICE_WALLET_ADDRESS.toString(),
              amount: `${priceNanoTon}`,
              payload: body.toBoc().toString("base64"), // payload with comment in body
            },
          ],
        };
        // 發送交易訊息
        const result = await tonConnectUI.sendTransaction(transaction);
        if (result) {
          console.log("Transaction result:", result);
          const returnData = {
            boc: result.boc,
            itemId: itemId,
          };
          const jsonString = JSON.stringify(returnData);
          // 將交易結果回傳給 Unity
          window.unityInstance.SendMessage(
            "BuyItemResult",
            "OnBuyItemWithTonResult",
            jsonString
          );
        } else {
          throw new Error("Transaction failed");
        }
      }
    } catch (error) {
      console.error("Error buying item with Ton:", error);
      if (window.unityInstance) {
        const errorMessage = error.message || error.toString();
        window.unityInstance.SendMessage("Error", "Error", errorMessage);
      }
    }
  };

  // 商城 Usdt 付費功能
  const handleBuyItemWithUsdt = async (event) => {
    console.log("BuyItemWithUsdt");
    try {
      if (!address) {
        handleConnectToWallet();
      } else {
        const priceNanoUsdt = event.detail.priceNanoUsdt;
        const userId = event.detail.userId;
        const itemId = event.detail.itemId;
        const jettonWalletAddress = event.detail.jettonWalletAddress;
        // 準備交易訊息
        const forwardPayload = beginCell()
          .storeUint(0, 32) // 0 opcode means we have a comment
          .storeStringTail(`User ${userId} buying item ${itemId} with USDT`)
          .endCell();
        const body = beginCell()
          .storeUint(0xf8a7ea5, 32) // jetton transfer op code
          .storeUint(0, 64) // query_id:uint64
          .storeCoins(priceNanoUsdt) // amount:(VarUInteger 16) -  Jetton amount for transfer (decimals = 6 - jUSDT, 9 - default)
          .storeAddress(INVOICE_WALLET_ADDRESS) // destination:MsgAddress
          .storeAddress(Address.parse(address)) // response_destination:MsgAddress
          .storeBit(0) // no custom payload
          .storeCoins(toNano("0.000001")) // forward amount - if >0, will send notification message
          .storeBit(1) // we store forwardPayload as a reference
          .storeRef(forwardPayload)
          .endCell();
        const transaction = {
          validUntil: Math.floor(Date.now() / 1000) + 360,
          messages: [
            {
              address: jettonWalletAddress, // sender jetton wallet
              amount: toNano(0.05).toString(), // for commission fees, excess will be returned
              payload: body.toBoc().toString("base64"), // payload with jetton transfer body
            },
          ],
        };
        // 發送交易訊息
        const result = await tonConnectUI.sendTransaction(transaction);
        if (result) {
          // 回傳交易資料給 Unity
          console.log("Transaction sent successfully");
          const returnData = {
            boc: result.boc,
            itemId,
          };
          const jsonString = JSON.stringify(returnData);
          window.unityInstance.SendMessage(
            "BuyItemResult",
            "OnBuyItemWithUsdtResult",
            jsonString
          );
        } else {
          throw new Error("Transaction failed");
        }
      }
    } catch (error) {
      console.error("Error buying item with Ton:", error);
      if (window.unityInstance) {
        const errorMessage = error.message || error.toString();
        window.unityInstance.SendMessage("Error", "Error", errorMessage);
      }
    }
  };

  // const handleBuyItemWithUsdt = async (event) => {
  //   console.log("BuyItemWithUsdt");
  //   try {
  //     if (!tonClient || !address) {
  //       handleConnectToWallet();
  //     } else {
  //       const priceNanoUsdt = event.detail.priceNanoUsdt;
  //       const userId = event.detail.userId;
  //       const itemId = event.detail.itemId;
  //       const uuid = uuidv4().replace(/-/g, "");
  //       const payload = `${userId}-${itemId}-${uuid}`;
  //       // 找出用戶的 Jetton Wallet
  //       const jettonMinter = new TonWeb.token.jetton.JettonMinter(
  //         tonweb.provider,
  //         { address: USDT_MASTER_ADDRESS.toString() } // USDT Master
  //       );
  //       const jettonWalletAddress = await jettonMinter.getJettonWalletAddress(
  //         new TonWeb.utils.Address(address)
  //       );
  //       // 檢查用戶餘額是否足夠
  //       const jettonWalletToGetData = new TonWeb.token.jetton.JettonWallet(
  //         tonweb.provider,
  //         {
  //           address: jettonWalletAddress,
  //         }
  //       );
  //       const walletData = await jettonWalletToGetData.getData();
  //       const walletBalance = walletData.balance.toString();
  //       if (BigInt(walletBalance) < BigInt(priceNanoUsdt)) {
  //         const jsonString = JSON.stringify({
  //           ok: false,
  //           message: "Insufficient balance",
  //           itemId,
  //         });
  //         window.unityInstance.SendMessage(
  //           "BuyItemWithUsdtResult",
  //           "OnBuyItemWithUsdtResult",
  //           jsonString
  //         );
  //       }
  //       // 發送交易請求
  //       const jettonWalletToSendTransfer = tonClient.open(
  //         JettonWallet.createFromAddress(
  //           Address.parse(jettonWalletAddress.toString(true, true, true))
  //         )
  //       );
  //       await jettonWalletToSendTransfer.sendTransfer(sender, {
  //         fwdAmount: 1n,
  //         comment: payload,
  //         jettonAmount: BigInt(priceNanoUsdt),
  //         toAddress: INVOICE_WALLET_ADDRESS,
  //         value: JETTON_TRANSFER_GAS_FEES,
  //       });
  //       console.log("Transaction sent successfully");
  //       const returnData = {
  //         ok: true,
  //         message: "Transaction sent successfully",
  //         address,
  //         payload,
  //         itemId,
  //       };
  //       const jsonString = JSON.stringify(returnData);
  //       window.unityInstance.SendMessage(
  //         "BuyItemWithUsdtResult",
  //         "OnBuyItemWithUsdtResult",
  //         jsonString
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error buying item with Ton:", error);
  //     if (window.unityInstance) {
  //       const errorMessage = error.message || error.toString();
  //       window.unityInstance.SendMessage("Error", "Error", errorMessage);
  //     }
  //   }
  // };

  // 取得 Ton 餘額功能
  const handleGetTonBalance = async (event) => {
    console.log("GetTonBalance");
    try {
      if (!address) {
        handleConnectToWallet();
      } else {
        const balance = await tonweb.getBalance(address);
        window.unityInstance.SendMessage(
          "TonWalletAccount",
          "OnGetTonBalance",
          balance.toString()
        );
      }
    } catch (error) {
      console.error("Error getting Ton balance:", error);
      if (window.unityInstance) {
        const errorMessage = error.message || error.toString();
        window.unityInstance.SendMessage("Error", "Error", errorMessage);
      }
    }
  };

  const handleGetUsdtBalance = async (event) => {
    console.log("GetUsdtBalance");
    let jettonWalletAddress = "";
    let usdtBalance = 0;
    try {
      if (!address) {
        handleConnectToWallet();
      } else {
        // 找出用戶的 Usdt Jetton Wallet
        const jettonMinter = new TonWeb.token.jetton.JettonMinter(
          tonweb.provider,
          { address: USDT_MASTER_ADDRESS.toString() } // USDT Master
        );
        jettonWalletAddress = await jettonMinter.getJettonWalletAddress(
          new TonWeb.utils.Address(address)
        );
        // 獲取用戶 Usdt Jetton 餘額
        const jettonWalletToGetData = new TonWeb.token.jetton.JettonWallet(
          tonweb.provider,
          {
            address: jettonWalletAddress,
          }
        );
        const walletData = await jettonWalletToGetData.getData();
        usdtBalance = walletData.balance.toString();
        jettonWalletAddress = jettonWalletAddress.toString(true, true, true);
      }
    } catch (error) {
      console.error("Error getting Ton balance:", error);
    } finally {
      // 回傳用戶 Usdt Jetton 餘額跟 Jetton Wallet 地址給 Unity
      const jsonString = JSON.stringify({
        jettonWalletAddress,
        usdtBalance,
      });
      window.unityInstance.SendMessage(
        "TonWalletAccount",
        "OnGetUsdtBalance",
        jsonString
      );
    }
  };

  const handleDailySignIn = async (event) => {
    console.log("DailySignIn");
    try {
      if (!address) {
        handleConnectToWallet();
      } else {
        const taskId = event.detail.taskId;
        // 建立交易訊息
        const body = beginCell()
          .storeUint(1, 32) // op (daily_sign_in)
          .storeUint(0, 64) // query id
          .endCell();
        const transaction = {
          validUntil: Math.floor(new Date() / 1000) + 360,
          messages: [
            {
              address: SIGN_IN_CONTRACT_ADDRESS.toString(),
              amount: toNano(0.05).toString(),
              payload: body.toBoc().toString("base64"),
            },
          ],
        };
        // 發送交易訊息
        const result = await tonConnectUI.sendTransaction(transaction);
        if (result) {
          // 回傳交易資料給 Unity
          console.log("Transaction sent successfully");
          const returnData = {
            boc: result.boc,
            taskId,
          };
          const jsonString = JSON.stringify(returnData);
          window.unityInstance.SendMessage(
            "SignInResult",
            "OnDailySignInResult",
            jsonString
          );
        } else {
          throw new Error("Transaction failed");
        }
      }
    } catch (error) {
      console.error("Error buying item with Ton:", error);
      if (window.unityInstance) {
        const errorMessage = error.message || error.toString();
        window.unityInstance.SendMessage("Error", "Error", errorMessage);
      }
    }
  };
};

export default TonConnectComponent;
