import { createContext, useState } from "react";
import { CHAIN } from "@tonconnect/ui-react";
import { TonClient } from "@ton/ton";
import { useTonConnect } from "@/hooks/useTonconnect";
import { useAsyncInitialize } from "@/hooks/useAsyncInitialize";
import { getHttpEndpoint } from "@orbs-network/ton-access";

const initialContext = {
  tonClient: new TonClient({
    endpoint: "https://toncenter.com/api/v2/jsonRPC",
  }),
};

export const TonClientContext = createContext(initialContext);

export const TonClientProvider = ({ children }) => {
  const { network } = useTonConnect();
  const [client, setClient] = useState();

  useAsyncInitialize(async () => {
    console.log("TON client initializing");
    if (!network) return;

    const endpoint = await getHttpEndpoint({
      network: network === CHAIN.MAINNET ? "mainnet" : "testnet",
    });

    console.log("TON client endpoint", endpoint);

    const tonClient = new TonClient({ endpoint });
    console.log("TON client created", tonClient);
    setClient(tonClient);
  }, [network]);

  return (
    <TonClientContext.Provider value={{ tonClient: client }}>
      {children}
    </TonClientContext.Provider>
  );
};
