import { beginCell, SendMode } from "@ton/core";

export class JettonWallet {
  static OPCODES = {
    TRANSFER: 0xf8a7ea5,
  };

  constructor(address) {
    this.address = address;
  }

  static createFromAddress(address) {
    return new JettonWallet(address);
  }

  /**
   * Sends message of jetton transfer to jetton wallet. More about jetton transfers here https://docs.ton.org/develop/dapps/asset-processing/jettons
   */
  async sendTransfer(provider, via, opts) {
    // constructing payload for jetton transfer
    const builder = beginCell()
      .storeUint(JettonWallet.OPCODES.TRANSFER, 32) // opcode for transfer. 0xf8a7ea5 is used
      .storeUint(opts.queryId ?? 0, 64)
      .storeCoins(opts.jettonAmount) // jetton amount to transfer. Be aware of decimals. Almost all jettons has 9, but USDT has 6. More about decimals https://docs.ton.org/develop/dapps/asset-processing/metadata#jetton-metadata-attributes
      .storeAddress(opts.toAddress) // jetton destination address. Use wallet address, not jetton address itself
      .storeAddress(via.address) // excesses address. Extra tons, sent with message, will be transferred here.
      .storeUint(0, 1) // custom payload. Empty in standard jettons
      .storeCoins(opts.fwdAmount); // notifications ton amount. In case of simple jetton transfer just 1 nanoTon is OK.

    // if comment needed, it stored as Cell ref
    if ("comment" in opts) {
      const commentPayload = beginCell()
        .storeUint(0, 32)
        .storeStringTail(opts.comment)
        .endCell();

      builder.storeBit(1);
      builder.storeRef(commentPayload);
    } else {
      // if not, store forward payload
      if (
        opts.forwardPayload &&
        typeof opts.forwardPayload === "object" &&
        "readCell" in opts.forwardPayload
      ) {
        builder.storeBit(0);
        builder.storeSlice(opts.forwardPayload);
      } else if (
        opts.forwardPayload &&
        typeof opts.forwardPayload === "object" &&
        "beginCell" in opts.forwardPayload
      ) {
        builder.storeBit(1);
        builder.storeRef(opts.forwardPayload);
      } else {
        builder.storeBit(0);
      }
    }

    // provider often obtained via client.open(contract) method
    await provider.internal(via, {
      value: opts.value, // value to pay gas
      sendMode: SendMode.PAY_GAS_SEPARATELY,
      body: builder.endCell(),
    });
  }

  async getWalletData(provider) {
    const { stack } = await provider.get("get_wallet_data", []);

    return {
      balance: stack.readBigNumber(),
      ownerAddress: stack.readAddress(),
      jettonMasterAddress: stack.readAddress(),
      jettonWalletCode: stack.readCell(),
    };
  }
}
