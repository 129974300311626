import UnityComponent from "../components/UnityComponent";
import TonConnectComponent from "../components/TonConnectComponent";

export default function Home() {
  return (
    <>
      <UnityComponent />
      <TonConnectComponent />
    </>
  );
}
