/* global BigInt */
import { useState, useEffect } from "react";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { useTonConnect } from "@/hooks/useTonconnect";
import { JettonWallet } from "@/wrappers/JettonWallet";
import { v4 as uuidv4 } from "uuid";
import TonWeb from "tonweb";

import {
  INVOICE_WALLET_ADDRESS,
  USDT_MASTER_ADDRESS,
  SIGN_IN_CONTRACT_ADDRESS,
} from "@/constants/common.js";
import { JETTON_TRANSFER_GAS_FEES } from "@/constants/fees.constants.js";

import { beginCell, toNano } from "@ton/ton";
import { Address, SendMode } from "@ton/core";

const TonConnectComponent = () => {
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const { sender, tonClient } = useTonConnect();
  const address = useTonAddress();
  const tonweb = new TonWeb();

  const [tonBalance, setTonBalance] = useState(0);
  useEffect(() => {
    (async () => {
      const result = await window.okxTonWallet.tonconnect.connect(2, {
        manifestUrl: "https://tonshi-21cac.web.app/manifest.json",
        items: [{ name: "ton_addr" }, { name: "ton_proof", payload: "123" }],
      });
      if (result.event === "connect") {
        console.log(result.payload.items[0].address);
        console.log(result.payload.items[1].proof);
      } else {
        console.log(result.payload.message);
      }
    })();
    const fetchBalance = async () => {
      if (!address) return;
      // 将钱包地址转换为 Address 对象
      const balance = await tonweb.getBalance(address);
      console.log("Ton balance:", balance);
      setTonBalance(balance);
    };

    fetchBalance();
  }, [address]);

  // 連接 Ton Wallet 功能
  const handleConnectToWallet = async (event) => {
    console.log("ConnectToWallet");
    try {
      tonConnectUI.openModal();
    } catch (error) {
      console.error("Error connecting to wallet:", error);
    }
  };

  // 解除連接 Ton Wallet 功能
  const handleDisconnectWallet = async (event) => {
    console.log("DisconnectWallet");
    try {
      await tonConnectUI.disconnect();
    } catch (error) {
      console.error("Error disconnecting wallet:", error);
    }
  };

  // 顯示 Ton Wallet 連接狀態
  const handleGetUserWalletAddress = async (event) => {
    console.log("GetUserWalletAddress");
    try {
    } catch (error) {
      console.error("Error getting wallet status:", error);
    }
  };

  // 商城 Ton 付費功能
  const handleBuyItemWithTon = async (event) => {
    console.log("BuyItemWithTon");
    try {
      if (!address) {
        handleConnectToWallet();
      } else {
        const priceNanoTon = event.detail.priceNanoTon || 10000000;
        const userId = event.detail.userId || "123";
        const itemId = event.detail.itemId || "456";
        const walletAddress =
          tonConnectUI?.connector?._wallet?.account?.address;
        if (!walletAddress) {
          throw new Error("Please connect wallet first");
        }
        // 建立交易訊息
        const body = beginCell()
          .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
          .storeStringTail(`User ${userId} buying item ${itemId} with TON`)
          .endCell();
        const transaction = {
          validUntil: Math.floor(new Date() / 1000) + 360,
          messages: [
            {
              address: INVOICE_WALLET_ADDRESS.toString(),
              amount: `${priceNanoTon}`,
              payload: body.toBoc().toString("base64"), // payload with comment in body
            },
          ],
        };
        // 發送交易訊息
        const result = await tonConnectUI.sendTransaction(transaction);
        console.log("Transaction result:", result);
        // 將交易結果回傳給 Unity
      }
    } catch (error) {
      console.error("Error buying item with Ton:", error);
      if (window.unityInstance) {
        window.unityInstance.SendMessage("Error", "Error", error);
      }
    }
  };

  // 商城 Usdt 付費功能
  const handleBuyItemWithUsdt = async (event) => {
    console.log("BuyItemWithUsdt");
    try {
      if (!address) {
        handleConnectToWallet();
      } else {
        const priceUsdtCent = event.detail.priceUsdtCent || 1;
        const userId = event.detail.userId || "123";
        const itemId = event.detail.itemId || "456";
        // 找出用戶的 Jetton Wallet
        const jettonMinter = new TonWeb.token.jetton.JettonMinter(
          tonweb.provider,
          { address: USDT_MASTER_ADDRESS.toString() } // USDT Master
        );
        const jettonWalletAddress = await jettonMinter.getJettonWalletAddress(
          new TonWeb.utils.Address(address)
        );
        console.log(jettonWalletAddress);
        // 檢查用戶餘額是否足夠
        // const jettonWalletToGetData = new TonWeb.token.jetton.JettonWallet(
        //   tonweb.provider,
        //   {
        //     address: jettonWalletAddress,
        //   }
        // );
        // const walletData = await jettonWalletToGetData.getData();
        // const walletBalance = walletData.balance.toString();
        // if (BigInt(walletBalance) < BigInt(priceUsdtCent * 10000)) {
        //   console.log("Insufficient balance");
        //   return {
        //     ok: false,
        //     message: "Insufficient balance",
        //     itemId,
        //   };
        // }
        // 準備交易訊息
        const forwardPayload = beginCell()
          .storeUint(0, 32) // 0 opcode means we have a comment
          .storeStringTail(`User ${userId} buying item ${itemId} with USDT`)
          .endCell();
        const body = beginCell()
          .storeUint(0xf8a7ea5, 32) // jetton transfer op code
          .storeUint(0, 64) // query_id:uint64
          .storeCoins(priceUsdtCent * 10000) // amount:(VarUInteger 16) -  Jetton amount for transfer (decimals = 6 - jUSDT, 9 - default)
          .storeAddress(INVOICE_WALLET_ADDRESS) // destination:MsgAddress
          .storeAddress(Address.parse(address)) // response_destination:MsgAddress
          .storeBit(0) // no custom payload
          .storeCoins(toNano("0.000001")) // forward amount - if >0, will send notification message
          .storeBit(1) // we store forwardPayload as a reference
          .storeRef(forwardPayload)
          .endCell();
        const transaction = {
          validUntil: Math.floor(Date.now() / 1000) + 360,
          messages: [
            {
              address: jettonWalletAddress.toString(true, true, true), // sender jetton wallet
              amount: toNano(0.05).toString(), // for commission fees, excess will be returned
              payload: body.toBoc().toString("base64"), // payload with jetton transfer body
            },
          ],
        };
        // 發送交易訊息
        const result = await tonConnectUI.sendTransaction(transaction);
        // 回傳交易資料給 Unity
        console.log("Transaction sent successfully");
        const returnData = {
          ok: true,
          message: "Transaction sent successfully",
          boc: result.boc,
          itemId,
        };
        const stringJson = JSON.stringify(returnData);
        console.log("Return data:", stringJson);
      }
    } catch (error) {
      console.log("Error during transaction check:", error);
    }
  };

  const handleGetUsdtBalance = async (event) => {
    console.log("GetUsdtBalance");
    let jettonWalletAddress = "";
    let usdtBalance = 0;
    try {
      if (!address) {
        handleConnectToWallet();
      } else {
        // 找出用戶的 Usdt Jetton Wallet
        const jettonMinter = new TonWeb.token.jetton.JettonMinter(
          tonweb.provider,
          { address: USDT_MASTER_ADDRESS.toString() } // USDT Master
        );
        jettonWalletAddress = await jettonMinter.getJettonWalletAddress(
          new TonWeb.utils.Address(address)
        );
        // 獲取用戶 Usdt Jetton 餘額
        const jettonWalletToGetData = new TonWeb.token.jetton.JettonWallet(
          tonweb.provider,
          {
            address: jettonWalletAddress,
          }
        );
        const walletData = await jettonWalletToGetData.getData();
        usdtBalance = walletData.balance.toString();
        jettonWalletAddress = jettonWalletAddress.toString(true, true, true);
      }
    } catch (error) {
      console.error("Error getting Ton balance:", error);
    } finally {
      // 回傳用戶 Usdt Jetton 餘額跟 Jetton Wallet 地址給 Unity
      const jsonString = JSON.stringify({
        jettonWalletAddress,
        usdtBalance,
      });
      // window.unityInstance.SendMessage(
      //   "TonWalletAccount",
      //   "OnGetUsdtBalance",
      //   jsonString
      // );
      console.log("Usdt Balance:", usdtBalance);
      console.log("Jetton Wallet Address:", jettonWalletAddress.toString());
    }
  };

  // 每日簽到功能
  const handleDailyCheckIn = async (event) => {
    console.log("DailyCheckIn");
    try {
      if (!address) {
        handleConnectToWallet();
      } else {
        const walletAddress =
          tonConnectUI?.connector?._wallet?.account?.address;
        if (!walletAddress) {
          throw new Error("Please connect wallet first");
        }
        // 建立交易訊息
        const body = beginCell()
          .storeUint(1, 32) // op (daily_sign_in)
          .storeUint(0, 64) // query id
          .endCell();
        const transaction = {
          validUntil: Math.floor(new Date() / 1000) + 360,
          messages: [
            {
              address: SIGN_IN_CONTRACT_ADDRESS.toString(),
              amount: toNano(0.05).toString(),
              payload: body.toBoc().toString("base64"), // payload with comment in body
            },
          ],
        };
        // 發送交易訊息
        const result = await tonConnectUI.sendTransaction(transaction);
        console.log("Transaction result:", result);
        // 將交易結果回傳給 Unity
      }
    } catch (error) {
      console.error("Error buying item with Ton:", error);
      if (window.unityInstance) {
        window.unityInstance.SendMessage("Error", "Error", error);
      }
    }
  };

  return (
    <div>
      <button onClick={handleConnectToWallet}>Connect to Wallet</button>
      <button onClick={handleDisconnectWallet}>Disconnect Wallet</button>
      <button onClick={handleGetUserWalletAddress}>
        Get User Wallet Address
      </button>
      <button onClick={handleBuyItemWithTon}>Buy With TON</button>
      <button onClick={handleBuyItemWithUsdt}>Buy With USDT</button>
      <button onClick={handleGetUsdtBalance}>Check USDT Balance</button>
      <button onClick={handleDailyCheckIn}>Daily Check In</button>
    </div>
  );
};

export default TonConnectComponent;
