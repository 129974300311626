import { useEffect, useRef, useState } from "react";
import WebApp from "@twa-dev/sdk";
import { DEFAULT_URL } from "../constants/common";

const UnityComponent = () => {
  const unityContainerRef = useRef(null);
  const canvasRef = useRef(null);
  const loadingBarRef = useRef(null);
  const progressBarFullRef = useRef(null);
  const progressTextRef = useRef(null);
  const [contentToCacheData, setContentToCacheData] = useState([]);
  useEffect(() => {
    fetch(`${DEFAULT_URL}/contentToCache.json`)
      .then((response) => response.json())
      .then((contentToCache) => {
        setContentToCacheData(contentToCache.data);
      })
      .catch((error) => console.error("Error fetching the data:", error));
  }, []);

  useEffect(() => {
    if (contentToCacheData.length > 0) {
      const user = WebApp.initDataUnsafe.user;

      // console.log(
      //   `userID = ${user.id} username = ${user.username} name = ${user.first_name} ${user.last_name}`
      // );

      // 註冊 Service Worker
      window.addEventListener("load", function () {
        console.log("Service Worker Registered");
        console.log("navigator", navigator);
        if ("serviceWorker" in navigator) {
          navigator.serviceWorker.register("ServiceWorker.js");
        }
      });

      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        const meta = document.createElement("meta");
        meta.name = "viewport";
        meta.content =
          "width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes";
        document.getElementsByTagName("head")[0].appendChild(meta);
      }

      // 显示加载条
      if (loadingBarRef.current) {
        loadingBarRef.current.style.display = "block";
      }

      // Unity 加載腳本
      const loaderUrl = contentToCacheData[0];
      console.log("loaderUrl", loaderUrl);
      const config = {
        dataUrl: contentToCacheData[2],
        frameworkUrl: contentToCacheData[1],
        codeUrl: contentToCacheData[3],
        streamingAssetsUrl: "StreamingAssets",
        companyName: "Rumuinno",
        productName: "Tonshi",
        productVersion: "0.1",
        showBanner: (msg, type) => unityShowBanner(msg, type),
      };

      const script = document.createElement("script");
      script.src = loaderUrl;
      script.onload = () => {
        window
          .createUnityInstance(canvasRef.current, config, (progress) => {
            if (progressBarFullRef.current) {
              progressBarFullRef.current.style.width = 80 * progress + "%";
              progressTextRef.current.textContent =
                "Loading... " + Math.round(80 * progress) + "%";
            }
          })
          .then((unityInstance) => {
            window.unityInstance = unityInstance;
          })
          .catch((message) => {
            alert(message);
            window.location.reload(true);
          });
      };
      document.body.appendChild(script);

      onOrientationChange();
      // 防止telegram下滑手勢關閉視窗跟導致點擊無法傳入unity
      window.scroll(0, document.body.scrollHeight);

      // 事件監聽器
      window.addEventListener("orientationchange", onOrientationChange);
      window.addEventListener("resize", onOrientationChange);
      document.addEventListener(
        "unityTelegramWebAPI_TelegramUserDataRequest",
        handleTelegramUserDataRequest
      );
      document.addEventListener("unityGameEvent_OnGameReady", handleGameReady);
      document.addEventListener("unityTelegramWebAPI_OpenLink", handleOpenLink);
      document.addEventListener(
        "unityTelegramWebAPI_OpenTelegramLink",
        handleOpenTelegramLink
      );
      document.addEventListener(
        "unityWebAPI_ClipboardCopy",
        handleClipboardCopy
      );
      document.addEventListener(
        "unityTelegramWebAPI_OpenInvoice",
        handleOpenInvoice
      );
      document.addEventListener("unityWebGLInput_OnBlur", handleInputOnBlur);
      document.addEventListener(
        "unityWebGLInput_ForceBlur",
        handleInputForceBlur
      );

      // 清理函數
      return () => {
        document.body.removeChild(script);
        window.removeEventListener("orientationchange", onOrientationChange);
        window.removeEventListener("resize", onOrientationChange);
        document.removeEventListener(
          "unityTelegramWebAPI_TelegramUserDataRequest",
          handleTelegramUserDataRequest
        );
        document.removeEventListener(
          "unityGameEvent_OnGameReady",
          handleGameReady
        );
        document.removeEventListener(
          "unityTelegramWebAPI_OpenLink",
          handleOpenLink
        );
        document.removeEventListener(
          "unityTelegramWebAPI_OpenTelegramLink",
          handleOpenTelegramLink
        );
        document.removeEventListener(
          "unityWebAPI_ClipboardCopy",
          handleClipboardCopy
        );
        document.removeEventListener(
          "unityTelegramWebAPI_OpenInvoice",
          handleOpenInvoice
        );
        document.removeEventListener(
          "unityWebGLInput_OnBlur",
          handleInputOnBlur
        );
        document.removeEventListener(
          "unityWebGLInput_ForceBlur",
          handleInputForceBlur
        );
      };
    }
  }, [contentToCacheData]);

  // 同步旋轉遊戲畫面功能
  const onOrientationChange = () => {
    const canvas = canvasRef.current;
    const angle = window.screen.orientation;

    if (angle === 0 || angle === 180) {
      // Portrait or upside down
      canvas.style.transform = "rotate(0deg)";
      canvas.style.width = "100%";
      canvas.style.height = "100%";
    } else if (angle === 90) {
      // Landscape - clockwise
      canvas.style.transform = "rotate(-90deg)";
      canvas.style.width = "100vh";
      canvas.style.height = "100vw";
    } else if (angle === -90) {
      // Landscape - counterclockwise
      canvas.style.transform = "rotate(90deg)";
      canvas.style.width = "100vh";
      canvas.style.height = "100vw";
    }
  };

  const unityShowBanner = (msg, type) => {
    function updateBannerVisibility() {
      warningBanner.style.display = warningBanner.children.length
        ? "block"
        : "none";
    }
    const warningBanner = document.getElementById("unity-warning");
    const div = document.createElement("div");
    div.innerHTML = msg;
    warningBanner.appendChild(div);
    if (type === "error") div.style = "background: red; padding: 10px;";
    else if (type === "warning") {
      div.style = "background: yellow; padding: 10px;";
      setTimeout(() => {
        warningBanner.removeChild(div);
      }, 5000);
    }
    updateBannerVisibility();
  };

  const handleTelegramUserDataRequest = (event) => {
    try {
      console.log("send");
      progressBarFullRef.current.style.width = "85%";
      progressTextRef.current.textContent = "Loading... 85%";
      window.unityInstance.SendMessage(
        "TelegramWebAPI",
        "OnGetTelegramUserData",
        window.Telegram.WebApp.initData
      );
    } catch (e) {
      console.warn("This is Telegram WebApp. Error: " + e);
    }
  };

  const handleGameReady = () => {
    if (loadingBarRef.current) {
      loadingBarRef.current.style.display = "none";
    }
  };

  const handleOpenLink = (event) => {
    try {
      console.log("open link");
      window.Telegram.WebApp.openLink(event.detail.url);
    } catch (e) {
      console.warn("This is Telegram WebApp. Error: " + e);
    }
  };

  const handleOpenTelegramLink = (event) => {
    try {
      console.log("open telegram link");
      window.Telegram.WebApp.openTelegramLink(event.detail.url);
    } catch (e) {
      console.warn("This is Telegram WebApp. Error: " + e);
    }
  };

  const handleClipboardCopy = (event) => {
    console.log("Clipboard");
    navigator.clipboard
      .writeText(event.detail.text)
      .then(() => {
        console.log("Async: Copying to clipboard was successful!");
      })
      .catch((err) => {
        execCopy(event)
          .then(() => {
            console.log("Async: execCopy was successful!");
          })
          .catch((err) => {
            console.error("Async: Could not copy text: ", err);
          });
      });
  };

  const execCopy = (event) => {
    console.log("execCopy");
    return new Promise((resolve, reject) => {
      let textarea = document.createElement("textarea");
      textarea.value = event.detail.text;
      textarea.style.position = "absolute";
      textarea.style.left = "-999999px";
      textarea.style.top = "-999999px";
      textarea.style.opacity = "0";
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      let is_copy = document.execCommand("copy");
      textarea.remove();
      (is_copy ? resolve : reject)();
    });
  };

  const handleOpenInvoice = (event) => {
    try {
      console.log("open Invoice");
      window.Telegram.WebApp.openInvoice(event.detail.url, (result) => {
        console.log("Invoice result: " + result);
        window.unityInstance.SendMessage(
          "TelegramWebAPI",
          "OnInvoiceResult",
          result
        );
      });
    } catch (e) {
      console.warn("This is Telegram WebApp. Error: " + e);
    }
  };

  const handleInputOnBlur = (event) => {
    try {
      console.log("onBlur");
      window.scrollTo(0, 0);
    } catch (e) {
      console.warn("This is Telegram WebApp. Error: " + e);
    }
  };
  const handleInputForceBlur = (event) => {
    try {
      console.log("forceBlur");
      window.scrollTo(0, 0);
    } catch (e) {
      console.warn("This is Telegram WebApp. Error: " + e);
    }
  };

  return (
    <div id="unity-container" ref={unityContainerRef}>
      <canvas
        id="unity-canvas"
        ref={canvasRef}
        width="960"
        height="600"
        tabIndex="-1"
      ></canvas>
      <div id="unity-loading-bar" ref={loadingBarRef}>
        <img id="unity-logo" src="/TemplateData/bg.png" />

        <div className="sushi-light">
          <img
            className="sushi-light-img"
            src="/TemplateData/sushi-light.png"
          />
        </div>
        <img className="sushi" src="/TemplateData/sushi.png" />
        <div id="unity-progress-bar-empty">
          <div id="unity-progress-bar-full" ref={progressBarFullRef}></div>
          <div id="unity-progress-text" ref={progressTextRef}>
            0%
          </div>
        </div>
        <div id="unity-loading-text">
          THE FIRST LOAD MAY TAKE LONGER,
          <br />
          THANK YOU FOR YOUR PATIENCE.
        </div>
      </div>
      <div id="unity-warning"></div>
    </div>
  );
};

export default UnityComponent;
