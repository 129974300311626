import { Address } from "@ton/core";

export const DEFAULT_URL = "https://tonshi-21cac.web.app";
export const BOT_URL = "https://t.me/OfficialTonshi_bot";
export const USDT_MASTER_ADDRESS = Address.parse(
  "EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs"
);
export const INVOICE_WALLET_ADDRESS = Address.parse(
  "UQC4vgdQAGYQ4c9KHyK5PYqKEoVhpE0V6z5nwJ3uEju-qHmF"
);
export const SIGN_IN_CONTRACT_ADDRESS = Address.parse(
  "EQBL2gC-1xpii5MJ7eTN2TsPHQvCDSPw_hbaie4nNFX0hIcb"
);
