import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/index";
import Test from "./pages/test";
import { DEFAULT_URL, BOT_URL } from "./constants/common";

function App() {
  return (
    <TonConnectUIProvider
      manifestUrl={`${DEFAULT_URL}/tonconnect-manifest.json`}
      // actionsConfiguration={{
      //   twaReturnUrl: `${BOT_URL}?startapp`,
      // }}
    >
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/test" element={<Test />} />
          </Routes>
        </Router>
      </div>
    </TonConnectUIProvider>
  );
}

export default App;
